import React from 'react'
import "./login2.css"
import { Link } from 'react-router-dom'
function Login2() {
  return (
<>
<section className='forLoginPagr'>
<div className="container">
  <div className="screen">

    <div className="screen__content">

      <form className="login">
      <img  className="login__input border-0" src={require("../../assets/images/WhatsApp_Image_2024-03-03_at_11.34.50_AM__1_-removebg-preview.png")} />
        <div className="login__field">
          <i className="login__icon fas fa-user" />
          <input
            type="text"
            className="login__input"
            placeholder="User name / Email"
          />
        </div>
        <div className="login__field">
          <i className="login__icon fas fa-lock" />
          <input
            type="password"
            className="login__input"
            placeholder="Password"
          />
        </div>
      <Link to="/dashboard" >  <button className="button login__submit">
          <span className="button__text">Get Started</span>
          <i className="button__icon fas fa-chevron-right" />
        </button></Link>
      </form>
      {/* <div className="social-login">
        <h3>log in via</h3>
        <div className="social-icons">
          <a href="#" className="social-login__icon fab fa-instagram" />
          <a href="#" className="social-login__icon fab fa-facebook" />
          <a href="#" className="social-login__icon fab fa-twitter" />
        </div>
      </div> */}
    </div>
    <div className="screen__background">
      <span className="screen__background__shape screen__background__shape4" />
      <span className="screen__background__shape screen__background__shape3" />
      <span className="screen__background__shape screen__background__shape2" />
      <span className="screen__background__shape screen__background__shape1" />
    </div>
  </div>
</div>
</section>
</>
  )
}

export default Login2